import { useEffect, useRef } from 'react';
import './ImageDrop.css';
import React from 'react';
export default function ImageDrop({onChange, image, children}) {

  const ref = useRef(null);

  useEffect(() => {
    ref.current.addEventListener('dragover', handleFileDragOver);
    ref.current.addEventListener('drop', handleFileDrop);
  
    return () => {
        ref.current.removeEventListener('dragover', handleFileDragOver);
        ref.current.removeEventListener('drop', handleFileDrop);
    };
  }, []);

  const handleFileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e?.dataTransfer?.files);
    onChange(e?.dataTransfer?.files?.[0]);
  }

  const handleFileDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  return (<>
        <label htmlFor="fileUpload" className="FileUploader" onDrop={handleFileDrop} onDragOver={handleFileDragOver} ref={ref}>
            {image !== undefined ? <img src={URL.createObjectURL(image)}/> : "Click or Drop Image"}
        </label>
        <input id="fileUpload" type="file" onChange={e => onChange(e?.target?.files?.[0])} accept="image/*"/>
    </>
  );
}
