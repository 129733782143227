import { createContext, FormEvent, useEffect, useRef, useState } from 'react';
import './App.css';
import React from 'react';
import Generator from './Generator';
import ImageDrop from './ImageDrop';

export const PromptContext = createContext({} as FormData);

export default function App() {
  const [model, setModel] = useState("@cf/lykon/dreamshaper-8-lcm");
  const [prompt, setPrompt] = useState("cyberpunk cat");
  const [APIKey, setAPIKey] = useState("");
  const [baseImg, setBaseImg] = useState(undefined as (File | undefined));
  const [maskImg, setMaskImg] = useState(undefined as (File | undefined));

  const baseRef = useRef(null);
  
  const submitForm = (e: FormEvent) => {
    e.preventDefault();
    setPromptInfo(constructFormData());
  }
  
  const constructFormData = () => {
    let data = new FormData()
    data.append("model", model);
    data.append("prompt", prompt);
    data.append("APIKey", APIKey);
    data.append("baseImg", baseImg as File);
    data.append("maskImg", maskImg as File);
    return data;
  }
  
  const [promptInfo, setPromptInfo] = useState(constructFormData() as FormData);

  return (<>
    <header>
      <p className='Title'>InstaPics</p>
      <form className='PromptForm' onSubmit={submitForm}>
        <div className='FormCol'>
          <ImageDrop onChange={setBaseImg} image={baseImg}/>
        </div>
        <div className='FormCol'>
          <label htmlFor="maskFile" className="FileUploader">
            <img src={maskImg !== undefined ? URL.createObjectURL(maskImg) : ""}/>
          </label>
          <input id="maskFile" type="file" onChange={e => setMaskImg(e?.target?.files?.[0])} onDrop={e => setMaskImg(e?.dataTransfer?.files?.[0])} accept="image/*"/>
        </div>
        <div className="FormCol MainForm">
          <div className='FormRow'>
            <select name="models" id="Models" value={model} onChange={e => setModel(e.target.value)}>
              <option value="@cf/lykon/dreamshaper-8-lcm">Dreamshaper</option>
              <option value="@cf/runwayml/stable-diffusion-v1-5-img2img">SD 1.5 + Img2Img</option>
              <option value="@cf/runwayml/stable-diffusion-v1-5-inpainting">SD 1.5 + Inpainting</option>
              <option value="@cf/stabilityai/stable-diffusion-xl-base-1.0">SDXL 1.0</option>
              <option value="@cf/bytedance/stable-diffusion-xl-lightning">SDXL Lightning</option>
            </select> 
            <input type="text" placeholder='Enter API Key' value={APIKey} onChange={e => setAPIKey(e.target.value)}/>
          </div>
          <div className='FormRow'>
            <input type="text" placeholder='Enter a cool prompt' value={prompt} onChange={e => setPrompt(e.target.value)}/>
            <input type="submit" value="Submit"/>
          </div>
        </div>
      </form>
      <div></div>
    </header>
    <div>
      <PromptContext.Provider value={promptInfo}>
        <div className='GeneratorGrid'>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
        </div>
      </PromptContext.Provider>
    </div>
    <div>
      <PromptContext.Provider value={promptInfo}>
        <div className='GeneratorGrid'>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
          <Generator setBase={setBaseImg}/>
        </div>
      </PromptContext.Provider>
    </div>
    </>
  );
}
