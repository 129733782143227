import { useContext, useEffect, useState } from 'react';
import './Generator.css';
import React from 'react';
import { PromptContext } from './App';

export default function Generator({setBase}) {
  const prompt = useContext(PromptContext) as FormData;
  const [result, setResult] = useState(undefined);

  const toggleZoom = () => {
    setBase(result);
  }

  useEffect(() => {
    setResult(undefined);
    fetch(window.location.toString().includes("localhost") ? "https://instapics.pages.dev/api" : "/api", {
      method: "POST",
      body: prompt
    }).then(result => result.blob())
    .then(blob => setResult(blob))
  }, [prompt]);


  return (<div className="ImageCard">
      {result !== undefined? <img className="Image" src={URL.createObjectURL(result)} onClick={toggleZoom}/> : <p>Generating</p> }
    </div>
  );
}
